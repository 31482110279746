import React, { Component } from 'react'
import ContactForm from './ContactForm'
import styled from "styled-components"
import BASE from "../../components/00_utilities/base"
import SpotLight from "../../images/spotlight--profile.svg"

const StyledSection = styled.div`
    display: flex;
    position: relative;
    padding-top: 215px;
    height: 582px;
    margin-top: 86px;
    // overflow: hidden;

    & > div {
        width: 70%;
        display: flex;
        margin: 0 auto;

    }

    .--left,
    .--right {
        width: 90vw;
        height: 100vw;
        position: absolute;
        opacity: 70%;
        mix-blend-mode: color-dodge;
        z-index: -1;
        transform: rotate( 137deg );
        pointer-events: none;

        &.--left {
            transform: scaleX(-1) rotate( 137deg );
            left: -35rem;
            bottom: -30rem;
        }
      }

      @media screen and ( max-width: 1020px ) {
        height: auto;
        & > div {
          flex-direction: column;
        }
      }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 42%;

  h1 {
    color: ${BASE.COLORS.yellow};
    font-family: ${BASE.FONTS.heebo};
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 50px;
    font-size: 21px;
    font-weight: 400;
  }

  h2,
  p {
    color: ${BASE.COLORS.white};
  }

  p {
    font-family: ${BASE.FONTS.heebo};
    font-size: 18px;
    line-height: 32px;
  }
`;

export default class FormContact extends Component {
    render() {
        return (
            <StyledSection id='get_a_quote'>
                <div>
                    <Content>
                        <h1>Get a Quote</h1>
                        <h2>Contact us or get a quote</h2>
                        <p>Have questions? Let us know your event needs and details and we'll get back to you as soon as we can.</p>
                    </Content>
                    <ContactForm />
                    <SpotLight className="--left" />
                </div>
            </StyledSection>
        )
    }
}
