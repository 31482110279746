import React, { Component } from 'react';
import styled from 'styled-components';
import BASE from '../00_utilities/base';
import ButtonBordered from '../atoms/ButtonBordered';

const StyledForm = styled.form`
    display: grid;
    grid-template-columns: repeat(2, 1fr );
    column-gap: 15px;
    width: 100%;
    margin-left: 9%;

    label {
        display: flex;
        flex-direction: column;
        color: ${BASE.COLORS.white};
    }

    .full {
        grid-column: span 2;
    }

    i {
        color: red;
    }

    #entertainment_type {
        grid-column: span 2;
        display: flex;
        flex-direction: row;

        a {
            border: 1px solid red;
            border-radius: 50%;
            color: red;
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            display: inline-flex;
            font-size: 16px;
            font-weight: 700;
            align-items: center;
            justify-content: center;
            margin-left: 1rem;
            cursor: pointer;

            &:hover {
                background: red;
                color: white;
                // border-color: white;
            }
        }
    }

    .aerial_container,
    .ground_container {

        label {
            display: flex;
            flex-direction: row;

            input {
                margin-right: .5rem;
            }
        }
    }

    .aerial_children_container,
    .ground_children_container {
        display: flex;
        flex-wrap: wrap;
        padding-left: 1rem;

        label {
            width: 45%;

            input {
                margin-right: .5rem;
            }
        }
    }

    .info {
        position: relative;

        &:hover,
        &:focus {
            .tooltip {
                transform: scale( 1 );
            }
        }
    }

    .tooltip {
        position: absolute;
        top: -6rem;
        left: 0;
        width: 500px;
        background: red;
        color: white;
        padding: 1rem;
        transform: scale( 0 );
        transition: all .2s ease-out;
        // box-shadow: 0 -.5rem 1rem .01rem rgba( 255, 255, 255, .3 );
    }

    button[type="submit"] {
        background: transparent;
        border: none;
        color: ${BASE.COLORS.blueLight};
        margin: 0 auto;
    }

    .submitBtn {
        width: 100%;
        text-align: center;
    }

    .borderBtn {
        grid-column: span 2;
    }

    @media screen and ( max-width: 1020px ) {
        margin-left: 0;
        margin-bottom: 86px;
    }

    @media screen and ( max-width: 676px ) {
        display: flex;
        flex-direction: column;
    }
`;

export default class ContactForm extends Component {

    render() {

        const handleInfoClick = (e) => {

        }

        const handleAerialsClick = () => {
            const aerialContainer = document.querySelector('.aerial_children_container');
            const parentAerialInput = document.querySelector('input[name="aerial"]');
            const aerialActs = Array.from( aerialContainer.querySelectorAll('input:checked') );

            if (aerialActs.length) {
                parentAerialInput.checked = true;
            } else {
                parentAerialInput.checked = false;
            }
        }

        const handleGroundClick = (e) => {
            const groundContainer = document.querySelector('.ground_children_container');
            const parentGroundInput = document.querySelector('input[name="ground"]');
            const groundActs = Array.from( groundContainer.querySelectorAll('input:checked') );

            if (groundActs.length) {
                parentGroundInput.checked = true;
            } else {
                parentGroundInput.checked = false;
            }
        }

        return (
            <StyledForm className="m-contact-form" method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <label className="full" htmlFor="name">Name
                    <input type="text" name="name" placeholder="John Doe" required/>
                </label>
                <label htmlFor="Phone">Phone
                    <input type="tel" name="phone" placeholder="000-000-0000" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" required/>
                </label>
                <label htmlFor="email">Email
                    <input type="email" placeholder="johndoe@email.com" required/>
                </label>
                <label htmlFor="event">Event Type
                    <select name="event" id="eventType" required>
                        <option value="birthday">Birthday</option>
                        <option value="anniversary">Anniversary</option>
                        <option value="wedding">Wedding</option>
                        <option value="corporate">Corporate Event</option>
                        <option value="religious">Religious Event</option>
                        <option value="other">Other</option>
                    </select>
                </label>
                <label htmlFor="date">Select Date
                    <input type="date" require />
                </label>
                <label id="entertainment_type" htmlFor="entertainment">Entertainment Type <a className="info" onClick={ (e) => handleInfoClick() }>i <span className="tooltip">AERIAL Acts require XYZ and can only be performed for X time. Ground performance required.</span></a></label>
                <div className="aerial_container">
                    <label htmlFor="aerial">
                        <input type="checkbox" name="aerial"/> Aerial</label>
                    <div className="aerial_children_container" onClick={handleAerialsClick}>
                        <label htmlFor="hoop"><input type="checkbox" name="hoop"/>Hoop</label>
                        <label htmlFor="trapeze"><input type="checkbox" name="trapeze" />Trapeze</label>
                        <label htmlFor="silks"><input type="checkbox" name="silks"/>Silks</label>
                        <label htmlFor="other_aerial"><input type="checkbox" name="other_aerial" />Other</label>
                    </div>
                </div>
                <div className="ground_container">
                    <label htmlFor="ground"><input type="checkbox" name="ground" />Ground</label>
                    <div className="ground_children_container" onClick={handleGroundClick}>
                        <label htmlFor="juggling"><input type="checkbox" name="juggling" />Juggling</label>
                        <label htmlFor="tightwire"><input type="checkbox" name="tightwire" />Tightwire</label>
                        <label htmlFor="Unicycle"><input type="checkbox" name="Unicycle" />Unicycle</label>
                        <label htmlFor="other_ground"><input type="checkbox" name="other_ground" id="" />other</label>
                    </div>
                </div>
                <label className="full" htmlFor="how">
                <textarea name="how" id="how" placeholder="Additional information"></textarea></label>
                <ButtonBordered>
                    <button type="submit">Get a Quote</button>
                </ButtonBordered>
            </StyledForm>
        )
    }
}
