import React from 'react';
import Performers from '../../../content/performers.json';
import { PerformerSlide } from './PerformerSlide';

export const PerformerSlideContainer = (data) => {
    Performers.map( performer => {
        const performerName = (performer.name).toLowerCase();
        performer.image = data.image[performerName];
    })

    return(
        <>
            {Performers.map( (performer) => (
                <PerformerSlide
                    {...performer}
                />
            ))}
        </>
    )
}