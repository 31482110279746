import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/organisms/Hero"
import AboutPreview from '../components/molecules/AboutPreview';
import FormContact from "../components/organisms/FormContact"
import ReviewSection from "../components/organisms/ReviewSection"
import ImageGrid from "../components/organisms/ImageGrid"
import PerformerSlider from "../components/organisms/PerformerSlider"
import Performers from '../../content/performers.json';
import { PerformerSlideContainer } from "../components/molecules/PerformerSlideContainer"

const IndexPage = ( props ) => (
  <Layout>
    <SEO title="Home" />
    <Hero
      title="Chandelier Circus Entertainment"
      subHeader="Let us illuminate your event"
    />
    <AboutPreview
      image={props.data}
    />
    <ImageGrid
      image={props.data}
    />
    <PerformerSlider image={props.data}/>
    <ReviewSection
      image={props.data} />
    <FormContact />
    <PerformerSlideContainer
      image={props.data}
    />
  </ Layout>
)

export default IndexPage

const addPerformersToQuery = () => {
  Performers.map( performer => {

  })
}

export const query = graphql`
query {
    rosie: file(relativePath: {eq: "rosieaerial.png"}) {
        childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
        }
    }
    duo: file( relativePath: { eq: "duo_hoop.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grid_1: file( relativePath: { eq: "grid_1.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grid_2: file( relativePath: { eq: "grid_2.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grid_3: file( relativePath: { eq: "grid_3.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chantal: file( relativePath: { eq: "performers/chantal.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    danger: file( relativePath: { eq: "performers/danger.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kelsey: file( relativePath: { eq: "performers/kelsey.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leah: file( relativePath: { eq: "performers/leah.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jasmine: file( relativePath: { eq: "performers/jasmine.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ellen: file( relativePath: { eq: "performers/ellen.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kristi: file( relativePath: { eq: "performers/kristi.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chloe: file( relativePath: { eq: "performers/chloe.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cornell: file( relativePath: { eq: "performers/cornell.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kyle: file( relativePath: { eq: "performers/kyle.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stephanie: file( relativePath: { eq: "performers/stephanie.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    YellowArrow: file( relativePath: { eq: "YellowArrow-Right.png"}) {
      childImageSharp {
        fluid(maxWidth: 42 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`;