import React from 'react'
import styled from 'styled-components'
import BASE from '../00_utilities/base';

const Review = ({ title, content, client, date, index }) => {
    let stateClass = "";
    if ( index == 0 ) {
        stateClass = "active"
    }

    const ReviewStyle = styled.div`
        display: flex;
        flex-direction: column;
        display: none;

        &[data-state="active"] {
            display: flex;
        }

        h4 {
            font-size: 24px;
            color: ${BASE.COLORS.white};
            font-weight: 300;
        }

        p {
            color:${BASE.COLORS.white};
        }

        p.client {
            // display: inline-flex;
            line-height: 1;
            margin-bottom: .5rem;
        }
        span {
            font-style: italic;
            line-height: 1;
            color: rgba( 255, 255, 255, .5);
        }
    `;
    return (
        <ReviewStyle data-state={stateClass} data-key={index}>
            <h4>{title}</h4>
            <p>{content}</p>
            <p className="client">{client}</p>
            <span>{date}</span>
        </ReviewStyle>
    )
}

export default Review;