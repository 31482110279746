import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import PerformerBorder from '../atoms/PerformerBorder';
// import { PerformerSlide } from './PerformerSlide';

const Slide = ( props ) => {

    // const performerName = props.performer.name

    const [isModalOpen, setIsModalOpen ] = useState(false);

    const imgSrc = props.images[((props.performer.name).toLowerCase())].childImageSharp.fluid;

    const performerName = (props.performer.name).toLowerCase();

    const handleSlideClick = (e) => {
        if ( !isModalOpen ) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    }

    useEffect(()=> {
        const overlay = document.querySelector('#main_overlay');
        const performerCard = document.querySelector(`#${performerName}`);
        // if the modal isn't open and the overlay isn't active don't do anything
        if ( !isModalOpen && !overlay.classList.contains('active')) {
            return;
        }
        // if the modal isn't open but the overlay is active remove the active overlay
        if ( (!isModalOpen) && (overlay.classList.contains('active'))) {
            overlay.classList.remove('active');
            performerCard.classList.remove('active');
        }
        // if the modal is open and the overlay isn't active add active
        if ( (isModalOpen) && !(overlay.classList.contains('active'))) {
            overlay.classList.add('active');
            performerCard.classList.add('active');
            overlay.addEventListener('click', () => {
                handleSlideClick();
            } );
        }
    })


  return (
    <div className="performer-border" onClick={handleSlideClick} data-key={props.key}>
        <PerformerBorder>
            <Img fluid={imgSrc}></Img>
        </PerformerBorder>
        <span>{props.performer.name}</span>
    </div>
)
}

export default Slide;