import React from 'react'
import styled from 'styled-components'
import BASE from '../00_utilities/base'
import Border from '../../images/deco-frame-blue.svg'
import PropTypes from "prop-types"

const BorderedDiv = styled.div`
    // border: 1px solid ${BASE.COLORS.yellow};
    // width: 47%;
    position: relative;
    // padding: 6%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    div {
        width: 100%;
    }

    .borderBtn {
        grid-column: span 2;

        svg {
            pointer-events: none;
        }

        button[type="submit"]{
            z-index: 10;
            cursor: pointer;
        }
    }

    @media screen and ( max-width: 1023px ) {
        width: 70%;
        margin: 2rem auto 0;
        padding: 10%;
    }

    @media screen and ( max-width: 667px ) {
        width: 100%;
        padding: 12%;
    }
`;

const StyledBorder = styled( props => <Border {...props} /> )`
    display:block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
`;

const ButtonBordered = ( { children } ) => {
    return (
        <BorderedDiv className="borderBtn">
            <StyledBorder />
            {children}
        </BorderedDiv>
    )
}

ButtonBordered.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ButtonBordered;