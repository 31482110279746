import React, { useState, Component } from 'react'
import Review from '../molecules/Review'
import Reviews from '../../../content/reviews.json'
import styled from 'styled-components'
import BASE from '../00_utilities/base'
import RightArrow from "../../images/right-arrow.svg"
import LeftArrow from "../../images/left-arrow.svg"
import Img from "gatsby-image"

export default class ReviewSection extends Component {

    render() {

        const state = {
            active: 0,
        }

        let data = this.props;

        const updateActiveReview = () => {
            let allReveiws = Array.from(document.querySelectorAll('div[data-state]'));
            allReveiws.map( singleReview => {
                if ( singleReview.dataset.key == state.active ) {
                    singleReview.dataset.state = "active"
                } else {
                    singleReview.dataset.state = "";
                }
            } )
        }

        const setState = ( updatedValue ) => {
            state.active = updatedValue;
            updateActiveReview();
        }

        const handleArrowClick = ( direction ) => {
            if ( (direction === 'left') && state.active === 0 ) {
                setState( Reviews.length - 1 );
                return;
            } else if ( direction === 'left'  ) {
                setState( state.active - 1 );
                return;
            } else if ( (direction === 'right') && (state.active === ( Reviews.length - 1 ) ) ) {
                setState( 0 );
                return;
            }
            else if ( direction === 'right' ) {
                setState( state.active + 1 );
                return;
            }
        }

        const ReviewSection = styled.section`
            display: flex;
            width: 60%;
            margin: 0 0 0 auto;
            left: auto;
            right: 0;
            border: 1px solid ${BASE.COLORS.yellow};
            padding: 84px 10vw 95px 41.5px;
            align-items: center;
            position: relative;

            .gatsby-image-wrapper {
                width: 40%;
                max-width: 484px;
                height: auto;
                max-height: 301px;
                position: absolute !important;
                transform: translateX( -60% );
            }

            @media screen and ( min-width: 1440px ) {
                width : 50%;
                margin: 0 auto;
            }

            @media screen and ( max-width: 1020px ) {
                box-sizing: border-box;
                flex-direction: column-reverse;
                width: 90%;
                margin: 86px auto;
                left: 0;
                right: 0;
                padding: 84px 20px;

                .gatsby-image-wrapper {
                    display: none;
                }
            }
        `;

        const ReviewNavigation = styled.div`
            display: flex;
            padding-right: 91px;
            align-self: flex-end;
            margin-top: auto;

            // right-arrow || bottom & Left

            // left-arrow  || top & bottom
            svg {

                &:first-child {
                    margin-right: 41px;
                }
                cursor: pointer;
                g {
                    opacity: .7;
                }
            }

            svg:hover {
                cursor: pointer;

                path,
                g {
                    opacity: 1;
                }
            }

            @media screen and ( max-width: 1020px ) {
                padding: initial;
                align-self: flex-start;
                margin-top: 20px;
            }
        `

        const ReviewWrapper = styled.div`
            display: flex;
            flex-direction: column;
            // width: 40%;

            h2 {
                color: ${BASE.COLORS.white};
            }

            @media screen and ( max-width: 1020px ) {
                h2 {
                    // line-height: 1;
                    // font-size: 48px;
                }
            }
        `

        return (
            <ReviewSection>
                <Img fluid={data.image.duo.childImageSharp.fluid} />
                <ReviewNavigation>
                    <LeftArrow onClick={ (e) => handleArrowClick( 'left' )} />
                    <RightArrow onClick={ (e) => handleArrowClick( 'right' ) } />
                </ReviewNavigation>
                <ReviewWrapper>
                    <h3>Reviews</h3>
                    <h2>See what others are saying</h2>
                    { Reviews.map( (review, index) => (
                        <Review
                            title={review.title}
                            content={review.review}
                            client={review.client}
                            date={review.date}
                            index={index}
                            key={index}
                        />
                    ))}
                </ReviewWrapper>
            </ReviewSection>
        )
    }
}
