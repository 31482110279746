import React from 'react'
import Img from 'gatsby-image';
import styled from 'styled-components';
import BASE from '../00_utilities/base';

export const PerformerSlide = (performer) => {

    const performerName = (performer.name).toLowerCase();
    const StyledPerformerSlide = styled.div`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: white;
        max-width: 950px;
        max-height: 548px;
        padding: 75px;
        box-sizing: border-box;
        opacity: 0;
        background: ${BASE.COLORS.purple};
        background: #000022;

        * {
            color: ${BASE.COLORS.white};
        }

        z-index: -110;

        &.active {
            z-index: 111;
            opacity: 1;
        }

        .performerSlide {
            display: grid;
            grid-template-columns: 311px 1fr;
            grid-template-rows: 1fr;
            height: 100%;
            grid-gap: 77px;
            position: relative;

            &__info {
                display: flex;
                flex-flow: column nowrap;
                overflow-y: scroll;
                padding: 1em;
            }

            h3 {
                font-family: ${BASE.FONTS.lapture};
                font-weight: bold;
                font-size: 38px;
            }

            p {
                font-size: 18px;
            }
        }

        .gatsby-image-wrapper {
            aspect-ratio: 311 / 397;
            max-width: 100%;
        }

        @media screen and (max-width: 900px) {

            width: 80vw;
            height: 80vh;
            max-height: none;
            padding: 2em 1em 2em 2em;

            .performerSlide {
                display: flex;
                flex-flow: column nowrap;

                .gatsby-image-wrapper {
                    display: none !important;
                }
            }
        }
    `;
    return (
        <StyledPerformerSlide id={performerName}>
            <div className="performerSlide">
                <Img fixed={performer.image.childImageSharp.fluid} />
                <div className="performerSlide__info">
                    <h3>{performer.name}</h3>
                    <p>{performer.bio}</p>
                </div>
            </div>
        </StyledPerformerSlide>
    )
}
