import React, { Component } from 'react'
import styled from 'styled-components'
import BASE from '../00_utilities/base';

export default class AboutReadOut extends Component {
    render() {
        const StyledAboutUs = styled.div`
            max-width: 80%;
            max-height: 70%;
            overflow-y: auto;
            position: fixed;
            background: ${BASE.COLORS.purple};
            background: #000022;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 5rem;
            opacity: 0;
            pointer-events: none;

            z-index: -100;

            &.active {
                opacity: 1;
                z-index: 101;
                pointer-events: initial;
            }
            span {
                font-size: 42px;
                position: absolute;
                top: 0;
                right: 2rem;
                color: #fff;
                transform: rotate( 45deg );
            }

            p {
                white-space: break-word;
            }

            @media screen and ( max-width: 676px ) {
                width: 85vw;
                height: 80vh;
                padding: 4rem 2rem;
            }
        `;
        return (
            <StyledAboutUs id="readMoreModal">
                <span className="closeBtn">+</span>
                <h2>About Us</h2>
                <p>Chandelier Circus Entertainment is a collaborative group of professional performers who share a love for the circus arts. Many of Chandelier’s performers have trained together for over a decade and the trust and chemistry we have with one another translates into the quality of our performances. Chandelier Circus Entertainment can truly offer the whole package for any event with our extensive background in circus arts, as well as our expertise in performing for a wide-range of audiences. With this experience and commitment, when you hire CCE, you are guaranteed a level of performance that is of both high-quality and difficult to find. While many troupes offer a single act for hire, Chandelier can offer you a variety of acts, such as: juggling, acrobatics, hula hoop, various aerial acts, contortion, unicycling, hand-balancing, and more! We also recently added a strolling table to our list of acts that you can book, which is a table with a performer in the middle who glides through the party delivering cocktails or appetizers to the guests. We also have a champagne skirt which is a giant metal skirt with a performer in the middle that glides and holds 72 glasses of champagne or wine to serve to guests. In addition, we have added a living red carpet to our repertoire.</p>

                <p>The acts that we perform can be dangerous if done incorrectly, so safety is always paramount to every single performance we do. We are committed to using industry best practices to ensure the health of our performers and your guests. Our internal mantra is "safety first, customer satisfaction next" We don't just advocate for our own success, we advocate for the success of your event as well. When your guests leave with smiles on their faces, we will know we have done our job. We understand that it can be difficult to find entertainment that is both thrilling and appropriate, which is why we made a decision early on not to feature traditional or modern sideshow acts. </p>
            </StyledAboutUs>
        )
    }
}
