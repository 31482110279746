import React, { Component } from 'react'
import BASE from '../00_utilities/base'
import styled from "styled-components"
import SpotLight from "../../images/spotlight--profile.svg"
import GoldenColumn from '../../images/golden-column.svg'

const Section = styled.section`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 115px;
    position: relative;
    max-width: 100vw;

    h3 {
        color: ${BASE.COLORS.yellow};
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 50px;
    }

    .--left,
    .--right {
        width: 90vw;
        height: 100vw;
        position: absolute;
        opacity: 70%;
        mix-blend-mode: color-dodge;
        z-index: 0;
        transform: rotate( 137deg );
        pointer-events: none;

        &.--left {
            transform: scaleX(-1) rotate( 137deg );
            left: -20rem;
        }

        &.--right {
            right: -20rem;
        }
    }

    .column {
        position: absolute;
        margin: 0 auto;
        bottom: 3rem;
    }
`

export default class Hero extends Component {
    render() {

        const p = this.props;

        return (
            <Section>
                <h3>{p.title}</h3>
                <h1>{p.subHeader}</h1>
                <SpotLight className="--left" />
                <SpotLight className="--right"/>
                <GoldenColumn className="column" />
            </Section>
        )
    }
}
