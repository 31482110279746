import React from 'react';
import styled from 'styled-components';
import BASE from '../00_utilities/base';
import Img from "gatsby-image";

const StyledGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( 12, 1fr );
    grid-template-rows: repeat( 14, 35px );
    column-gap: 1vw;
    row-gap: 1vw;
    max-width: 80%;
    margin: 0 auto;

    h2 {
        color: ${BASE.COLORS.white};
    }

    img {
        // bottom: 0 !important;
        // top: auto !important;
        // padding-bortom: 0 !important;
        object-position: center bottom !important;
    }

    .img-1 {
        grid-column: span 3;
        grid-row: span 4;
    }

    .img-2 {
        grid-column: span 6;
        grid-row: span 6;
    }

    .content {
        padding-top: 2em;
        grid-column: span 3;
        grid-row: span 6;
    }

    .img-3 {
        grid-column: span 3;
        grid-row: 5 / 14;
    }

    .img-4 {
        grid-column: span 2;
        grid-row: 7 / 14;
        // grid-column-start: 4;
    }

    .img-5 {
        grid-column: span 7;
        grid-row: 7 / 14;
        // grid-row-start: 5;
    }
`;

export default ( data ) => (

    <div>

        <StyledGridContainer>
            <Img className="img-1" fluid={data.image.rosie.childImageSharp.fluid} />
            <Img className="img-2" fluid={data.image.grid_1.childImageSharp.fluid} />
            <div className="content">
                <h3>What to expect</h3>
                <h2>Just Look Skyward</h2>
            </div>
            <Img className="img-3" fluid={data.image.grid_2.childImageSharp.fluid} />
            <Img className="img-4" fluid={data.image.grid_3.childImageSharp.fluid} />
            <Img className="img-5" fluid={data.image.duo.childImageSharp.fluid} />
        </StyledGridContainer>

    </div>
)
