import React from 'react'
import Slider from 'react-slick';
import Performers from '../../../content/performers.json';
// import Img from 'gatsby-image';
import BASE from '../00_utilities/base';
import styled from 'styled-components';
import Slide from '../molecules/Slide';
import arrow from '../../images/YellowArrow-Right.png'



const PerformerSlider = ( data ) => {

        const StyledSlider = styled.div`
        max-width: 1440px;
        margin: 86px auto;
        // background: #fff;
        box-sizing: border-box;
        position: relative;
        padding: 0 15em;
        // overflow: hidden;
        text-align: center;

        h2 {
            color: ${BASE.COLORS.white};
        }

        .slick-slider {
            // display: flex;
            // display: grid;
            // grid-template-columns: 1fr 3fr 1fr;
            // max-width: 100%;
            // position: relative;
            // box-sizing: border-box;
        }

        .slick-list {
        }

        .slick-track {
            display: flex;
            padding: 4rem 0;
        }

        .slick-slide {
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            padding: 1rem;
            // max-width: 260px;
            transform: translateY( 0 );
            transition: all .5s ease-out 1s;
            box-sizing: border-box;

            // &.slick-active:nth-child( even ) {
            //     transform: translateY( -2rem );
            // }

            // &.slick-active:nth-child( odd ) {
            //     transform: translateY( 2rem );
            // }

            .performer-border > div {
                width: 262px;
                height: 460px;

                margin-bottom: 2rem;

                svg {
                    opacity: 0;
                    transition: all .3s linear 1.5s;
                }

                img {
                    filter: brightness( 10% );
                }
            }

            &.slick-active {
                transform: translateY( -2rem );
                transition: all .5s ease-out 1s;

                .performer-border > div {

                    img {
                        filter: brightness( 40% );
                    }
                }
            }

            &.slick-current {
                transform: translateY( -4rem );
                transition: all .5s ease-out 1s;

                .performer-border > div {

                    svg {
                        opacity: 1;
                        transition: all .3s linear 1.5s;
                    }

                    img {
                        filter: brightness( 95% );
                    }
                }
            }

            .gatsby-image-wrapper {
                width: 230px;
                height: 345px;
            }



            span {
                font-family: ${BASE.FONTS.lapture};
                color: ${BASE.COLORS.white};
                font-size: 28px;
                font-weight: 600;
            }
        }



        .slick-active:nth-child(2) {
            // transform: translateY( 0 );
        }

        .slick-arrow {
            position: absolute;
            z-index: 1;
            top: 50%;
        }

        .slick-prev,
        .slick-next {
            font-size: 0px;
            line-height: 0px;
            outline: none;
            background: transparent;
            border: none;
            width: 42px;
            height: 97px;
            cursor: pointer;
            transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:before {
                content: "";
                display: flex;
                height: 100%;
                width: 100%;
                background-image: url(${arrow});
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            &:hover {
                transform: scale(1.3);
            }
        }

        .slick-prev {
            left: 0;

            &:before {
                transform: rotate(180deg);
            }
        }

        .slick-next {
            right: 0;
        }

        @media screen and (max-width: 1340px ) {
            .slick-slide {
                transform: scale(.7);
            }

            .slick-slide.slick-active {
                transform: translateY(-1rem) scale(.7);
            }
        }
        @media screen and (max-width: 1100px ) {
            .slick-slide {
                transform: scale(1);
            }

            .slick-slide.slick-active {
                transform: translateY(-2rem) scale(1);
            }
        }
        @media screen and (max-width: 860px ) {
            padding: 0 3rem;

            .slick-slide {
                transform: scale(1);
            }

            .slick-slide.slick-active {
                transform: translateY(-2rem) scale(1);
            }
        }
        `;

        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            centerMode: true,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1340,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      infinite: true,
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                    }
                },
            ]
        }

        return (
        <StyledSlider>
            <h3>Our Performers</h3>
            <h2>Professional & Passionate</h2>
            <Slider {...settings}>
            { Performers.map( (performer, index ) => (
            <Slide
                    performer={performer}
                    images={data.image}
                    key={index}
            />
            ))}
            </Slider>
        </StyledSlider>
        )

}

export default PerformerSlider;