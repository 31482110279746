import React from 'react'
import BASE from '../00_utilities/base'
import styled from 'styled-components'
import ImageBordered from '../atoms/ImageBordered'
import {Link, graphql} from 'gatsby'
import Img from "gatsby-image"
import AboutReadOut from './AboutReadOut'
// import Rosie from '../../images/rosieaerial.png'

const Section = styled.section`
    padding: 86px 10%;
    display: flex;
    justify-content: space-between;
    position: relative;



    h3{
        color: ${BASE.COLORS.yellow};
        line-height: 31px;
    }

    h2 {
        color: ${BASE.COLORS.white};
    }

    p {
        color: ${BASE.COLORS.white};
        font-family: ${BASE.FONTS.heebo};
        font-size: 18px;
        line-height: 32px;
    }

    @media screen and ( max-width: 1023px ) {
        flex-direction: column;
    }
`;

// const StyledLink = styled( props => <Link {...props} /> ) `
const StyledLink = styled.a`
    color: ${BASE.COLORS.white};
    font-family: ${BASE.FONTS.heebo};
    font-size: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    position: relative;
    text-decoration: none;
    cursor: pointer;

    &:before,
    &:after {
        transition: all .2s ease-out;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 120%;
        height: 1px;
        bottom: -3px;
        background: ${BASE.COLORS.blueLight};
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 140%;
        height: 1px;
        bottom: -13px;
        background: ${BASE.COLORS.blueLight};
    }

    &:hover {
        &:before {
            width: 130%;
        }
        &:after {
            width: 150%;
        }
    }
`;

const Content = styled.div`
    width: 43%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and ( max-width: 1023px ) {
        width: 100%;
    }
`;

const closeLightbox = () => {
    const allActive = Array.from( document.querySelectorAll('.active') );


    allActive.map( active => {
        active.classList.remove('active');
    })
}

const handleLearnMoreBtn = () => {
    const overlay = document.querySelector('#main_overlay');
    const readMoreModal = document.querySelector('#readMoreModal');
    const closeBtn = readMoreModal.querySelector('.closeBtn');

    if ( overlay.classList.contains( 'active' ) ) {
        return;
    } else {
        overlay.classList.add( 'active' );
        readMoreModal.classList.add( 'active' );
        overlay.addEventListener( 'click', (e) => {
            closeLightbox()
        });
        closeBtn.addEventListener( 'click', (e) => {
            closeLightbox();
        })

    }
}

export default ( data ) => (
    <Section>
        <Content>
            <h3>About Us</h3>
            <h2>Character. Intelligence. Strength. Style.</h2>
            <p>We are a collection of local performers who share a love of the circus arts. We have trained performed and trusted each other for years and trust equates to safety.</p>
            <StyledLink onClick={ (e) => handleLearnMoreBtn() }>Learn more</StyledLink>
        </Content>
        <ImageBordered>
            <Img fluid={data.image.rosie.childImageSharp.fluid} />
        </ImageBordered>
        <AboutReadOut />
    </Section>
)