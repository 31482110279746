import React from 'react'
import styled from 'styled-components'
import BASE from '../00_utilities/base'
import Border from '../../images/DecoFrame.svg'
import PropTypes from "prop-types"

const BorderedDiv = styled.div`
    // border: 1px solid ${BASE.COLORS.yellow};
    width: 47%;
    position: relative;
    padding: 6%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 100%;
    }

    @media screen and ( max-width: 1023px ) {
        width: 70%;
        margin: 2rem auto 0;
        padding: 10%;
    }

    @media screen and ( max-width: 667px ) {
        width: 100%;
        padding: 12%;
    }
`;

const StyledBorder = styled( props => <Border {...props} /> )`
    display:block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

const ImageBordered = ( { children } ) => {
    return (
        <BorderedDiv>
            <StyledBorder />
            {children}
        </BorderedDiv>
    )
}

ImageBordered.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ImageBordered;